<template>
<div class="app-container">
  <!-- banner -->
  <div class="banner">
    <img  :src="bannerData.pictureUrl" class="pos-rl" v-if="bannerData.picture" :onerror="getImage(bannerData)" alt="" />
    <div class="left">
      <h2>News</h2>
      <div class="intro-text">
        You are here:  HomePages / News
      </div>
    </div>
  </div>
  <!-- news-list -->
  <div class="news columns inner" v-if="pageData">
    <div class="center-header" v-if="pageData.contentManagement">
      <h3>{{pageData.contentManagement.title}}</h3>
      <span>{{pageData.contentManagement.description}}</span>
    </div>
    <div class="new-box dis-flex" v-if="newsData.first">
      <!-- 左侧新闻 -->
      <div class="left">
        <div class="pic" @click="goToDetail(newsData.first,0)">
        <img :src="newsData.first.pictureUrl" v-if="newsData.first.picture" :onerror="getImage(newsData.first)" alt="">
        <!-- <img  :src="bannerData.pictureUrl" class="pos-rl" v-if="bannerData.pictureUrl" :onerror="getImage(bannerData)" alt="" /> -->
        <!-- <img src="../../static/client.png" v-else alt=""> -->
        </div>
        <div class="content">
            <h4 v-html="newsData.first.newsHeadlines" @click="goToDetail(newsData.first)"></h4>
            <div class="text p16-txt ft-slim" v-html="newsData.first.content">
            </div>
            <el-button class="read-btn" @click="goToDetail(newsData.first)">Reading</el-button>
          </div>
      </div>
      <!-- 右侧列表 -->
      <div class="right">
        <div class="news-list">
          <ul>
            <li class="pos-rl" v-for="(item,index) in newsData.list" :key="item.id">
              <div class="title" @click="goToDetail(item),index+1">
                <h4 class="dis-inbl">{{item.newsHeadlines}}</h4>
                <span v-html="item.updateTime.slice(0,10)"></span>
              </div>
              <div class="detail p16-txt" v-html="item.content">
              </div>
              <div class="arrow" @click="goToDetail(item),index+1"></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 分页 -->
    <div class="pagination dis-flex">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page="pageConfig.currentPage"
        :page-size="pageConfig.pageSize"
        layout="prev, pager, next"
        :total="pageConfig.total">
      </el-pagination>
    </div>
  </div>
</div>
</template>

<script>
import Cookies from 'js-cookie'
import router from '@/router'
import {post, del, get, putUrl} from "@/api/http";
import request from "@/utils/request.js";

export default {
  name: 'Home',
  // components: { InfoDialog, UpdatePassword,TagsView, sideMenu},
  data() {
    return {
      pageConfig: {
        pageSize: 4,
        total: 0,
        currentPage: 1,
        pageSizes: [
            10, 50, 100, 150, 200]
      },
      pageData:{},
      newsData:{},
      parentData:{},
      bannerData:{},

    }
  },
  methods: {
    handleCurrentChange(val) {
        this.pageConfig.currentPage = val;
        this.getList()
    },
    getList(){
      post("pressCenter/getPage",{
        page:this.pageConfig.currentPage,
        limit:this.pageConfig.pageSize,
      }).then(res => {
          if (res.code == 1) {
              // this.pageData=res.data.data
              this.formatData(res.data.data)
              this.pageConfig.total=res.data.count
          } else {
              this.$message.error(res.data);
          }
      });
    },
    // 数据结构
    formatData(list){
      let arr =[]
      let first={}
      list.map((ele,inx)=>{
        if(inx==0){
          first = list[0]
        }else{
          arr.push(ele)
        }
      })
      this.newsData={
        first:first,
        list:arr
      }
      console.log(this.newsData);
    },
    getData(){
      if(this.parentData.id){
        post("/publicController/publicData",{id:this.parentData.id}).then(res => {
            if (res.code == 1) {
                this.pageData=res.data.business[0]
                this.bannerData=res.data.homeIntroduction[0]
                console.log(this.pageData);
            } else {
                this.$message.error(res.data);
            }
        });
      }
    },
    getBgStyle(item){
      if (item.picture) {
        return {
        backgroundImage:
          'url(' + item.picture + ') ',
        }
      } else {
        return {
        backgroundImage:
          'url(' +
          require('../../static/news-banner.jpg') +
          ') ',
        }
      }
    },
    getImage(row) {
      setTimeout(function () {
        if (!row.pictureUrl){
          request({
            url: "/file/download",
            method: "POST",
            data: {
                id:row.picture
            },
            isDownLoadFile: true,
            isParams: true,
            responseType: "blob"
          }).then(res=>{
            const blob = new Blob([res], {
                type:"application/pdf"
            });
            const linkNode = document.createElement("a");
            linkNode.style.display = "none";
            linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
            document.body.appendChild(linkNode);
            row.pictureUrl =  linkNode.href 
          })
        }
      }.bind(this), 1000);
    },
    // 详情页
    goToDetail(data,index){
      data.banner=this.bannerData
      data.page = this.pageConfig.currentPage
      this.$router.push({ path: "/news-detail", query: data });
    },
    clearDialog() {
      this.infoDialog = false
      this.updateDialog = false
    },
  },
  mounted() {

    this.parentData = this.$route.query
    this.getList()
    this.getData()
  },
}
</script>
<style lang="scss" scoped>
.inner{
  width: 1620px;
  margin:0 auto;
}
.columns{
  padding:100px 0
}
.center-header{
  text-align: center;
    h3{
      font-size: 48px;
      line-height: 90px;
    }
    span{
      font-size: 22px;
      font-weight: 300;
    }
}
// banner
.banner{
  background-color: #F2F7FD;
  background-image: url('../../static/news-banner.jpg');
  background-size:cover;
  width: 100%;
  position: relative;
  height: 410px;
  overflow: hidden;
.left{
  position: absolute;
  left: 250px;
  width:32%;
  top:110px;
  color:#fff;

  h2{
    font-weight: 800;
    font-size: 76px;
  }
  .intro-text{
    font-size: 22px;
    font-weight: 300;
    line-height: 40px;
    margin-top:20px;
  }
}
}

.news{
  padding:100px 0 100px;
  // margin: 0 100px;
  .new-box{
    margin-top: 100px;
  }
  .left{
    width:620px;
    box-shadow: 0px 20px 27px 0px rgb( 246, 246, 246 );
    border-radius: 20px;
    overflow: hidden;
    // margin-right: 2%;
  
  .pic{
    border-radius: 20px;
    overflow: hidden;
    width: 620px;
    height: 320px;
    background-color: #F1F3F4;
    cursor: pointer;
    img{
      display: block;
      width:100%;
    }
  }
    .content{
      padding:20px;
    }
    h4{
      margin-bottom: 20px;
      line-height: 28px;
      min-height: 28px;
      max-height: 56px;
      cursor: pointer;
      width: 100%;
      overflow : hidden;
      display: -webkit-box;    /*弹性伸缩盒子*/
      -webkit-box-orient: vertical; /*子元素垂直排列*/
      -webkit-line-clamp: 2;   /*可以显示的行数，超出部分用...表示*/
      text-overflow: ellipsis; 
    }
    .text{
      font-size: 16px;
      line-height: 190%;
      // height: 320px;
      margin-bottom: 20px;
      width: 100%;
      overflow : hidden;
      display: -webkit-box;    /*弹性伸缩盒子*/
      -webkit-box-orient: vertical; /*子元素垂直排列*/
      -webkit-line-clamp: 10;   /*可以显示的行数，超出部分用...表示*/
      text-overflow: ellipsis; 
      p{
        margin-bottom: 22px;
      }
    }
    .read-btn{
      color:#fc7700;
      background-color: #FFF5EC;
      border-color: #FFF5EC;
      border-radius: 25px;
      padding:17px 45px;
      margin:20px 0;
    }
  }
  .right{
    flex:1;
    padding: 0 50px;
    width: 60%;
    li{
      padding:45px;
      min-height: 187px;
      border-bottom: 1px dotted rgb( 172, 172, 172 );
      .title{
        margin-bottom: 23px;
        cursor: pointer;
        h4{
          line-height: 1.5;
          width: 100%;
          overflow : hidden;
          display: -webkit-box;    /*弹性伸缩盒子*/
          -webkit-box-orient: vertical; /*子元素垂直排列*/
          -webkit-line-clamp: 2;   /*可以显示的行数，超出部分用...表示*/
          text-overflow: ellipsis; 
        }
        span{
          display: block;
          // margin-left: 30px;
          line-height: 42px;
          font-size: 16px;
          color:#5c5c5c;
        }
      }
      .title::before{
          width:15px;
          height: 15px;
          border-radius: 15px;
          background-color: #0D63DC;
          content: '';
          display: block;
          position: absolute;
          left: 0px;
          top:54px
        }
      .detail{
        font-size: 16px;
        line-height: 1.5;
        width: 100%;
        height: 92px;
        color:#333;
        overflow : hidden;
        display: -webkit-box;    /*弹性伸缩盒子*/
        -webkit-box-orient: vertical; /*子元素垂直排列*/
        -webkit-line-clamp: 4;   /*可以显示的行数，超出部分用...表示*/
        text-overflow: ellipsis; 
      }
      .arrow{
        content: '';
        width: 17px;
        height: 37px;
        display: block;
        cursor: pointer;
        position: absolute;
        right: 0;
        bottom: 80px;
        background: url('../../static/arrow.png') no-repeat;
      }
    }
    // li::after{
    //     content: '';
    //     width: 17px;
    //     height: 37px;
    //     display: block;
    //     cursor: pointer;
    //     position: absolute;
    //     right: 0;
    //     bottom: 80px;
    //     background: url('../../static/arrow.png') no-repeat;
    // }
    
  }
}
.pagination{
  margin:80px auto 100px;
  justify-content: center;
}
::v-deep .el-pagination.is-background {
  .btn-next, .btn-prev, .el-pager li{
    background-color: #3960D9;
    color:#fff;
    border-radius: 100%;
    height: 50px;
    width: 50px;
    line-height: 50px;
  }
  .el-pager li:not(.disabled).active{
    background-color: #262626;
  }
}

</style>
